<template>
    <div :class="['sim-swiper', {
        'sim-swiper--carousel': type === 'carousel',
        'sim-swiper--banner': type === 'banner',
        'sim-swiper--gallery-main': type === 'gallery-main',
        'sim-swiper--gallery-thumbs': type === 'gallery-thumbs',
    }]"
    >
        <!--  slot for navigation  -->
        <slot />

        <!--  CONTENT  -->
        <div ref="swiperEl"
             class="swiper"
             :class="{
                 'sim-swiper-horizontal': direction === 'horizontal',
                 [`sim-swiper-horizontal--${directionHorizontalAfter}`]: directionHorizontalAfter,
                 'sim-swiper-vertical': direction === 'vertical',
                 [`sim-swiper-vertical--${directionVerticalAfter}`]: directionVerticalAfter,
             }"
        >
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in slides"
                     :key="index"
                     ref="slideWrapper"
                     class="swiper-slide"
                     :inert="isSlideInert(index)"
                >
                    <slot name="slide"
                          v-bind="{
                              slide: slide,
                              index: index,
                              active: index === activeSlideIndex,
                              isFirst: index === 0,
                          }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup generic="T">
/**
 * Swiper
 *
 * @link
 *  [Swiper Documentation](https://swiperjs.com/swiper-api)
 * @link
 *  [Swiper Events Documentation](https://swiperjs.com/swiper-api#events)
 */

import Swiper from 'swiper'
import type { SwiperOptions } from 'swiper/types'
import type { ScssBreakpoint } from '@core-types/scss'
import { A11y, Autoplay, Thumbs, Keyboard, Mousewheel } from 'swiper/modules'
const { t } = useI18n()

type SwiperType = 'carousel' | 'banner' | 'gallery-main' | 'gallery-thumbs'

const {
    type,
    slides,
    endThreshold = 3,
    withMouse,
    direction = 'horizontal',
    directionVerticalAfter,
    directionHorizontalAfter,
    slideGap,
} = defineProps<{
    type: SwiperType
    slides: T[]
    /**
     * How many slides before the end should the `endReached` event be emitted.
     * @default 3
     */
    endThreshold?: number
    /**
     * Whether to allow mouse wheel navigation.
     * @default false
     */
    withMouse?: boolean
    /**
     * The direction of the swiper.
     * @default 'horizontal'
     */
    direction?: 'horizontal' | 'vertical'
    /**
     * The css breakpoint at which the direction of the swiper should change to vertical.
     * Cannot be used with `directionHorizontalAfter`.
     */
    directionVerticalAfter?: ScssBreakpoint
    /**
     * The css breakpoint at which the direction of the swiper should change to horizontal.
     * Cannot be used with `directionVerticalAfter`.
     */
    directionHorizontalAfter?: ScssBreakpoint
    /**
     * The gap between slides in pixels.
     * No gap is added when there are multiple slides visible. You need to control that with CSS due to SSR.
     * @default 30
     */
    slideGap?: number
}>()

const emit = defineEmits<{
    endReached: []
}>()


const verticalAfterBreakpoint = directionVerticalAfter ? useScssBreakpoints().greaterOrEqual(directionVerticalAfter) : null
const horizontalAfterBreakpoint = directionHorizontalAfter ? useScssBreakpoints().greaterOrEqual(directionHorizontalAfter) : null

const clientDirection = computed(() => {
    if (verticalAfterBreakpoint?.value) return 'vertical'
    if (horizontalAfterBreakpoint?.value) return 'horizontal'
    return direction
})

watch(clientDirection, (newDirection) => {
    injected?.swiper.value?.changeDirection(newDirection)
})


const slideWrapper = ref<HTMLDivElement[] | null>(null)

const isSwiperWithMultipleSlidesVisible = computed(() => {
    return ([
        'carousel',
        'gallery-thumbs',
    ] satisfies SwiperType[]).includes(type as any)
})

// const activeSlideIndex = computed<number>(() => injected?.activeSlideIndex.value ?? 0)
const activeSlideIndex = ref<number>(0)

const isPhotoswipeOpen = usePhotoswipeOpenState()
watch (isPhotoswipeOpen, (val) => {
    if (val) {
        injected?.swiper.value?.disable()
    } else {
        injected?.swiper.value?.enable()
    }
})

const swiperSettings = computed(() => {
    // Define default settings
    const settings: SwiperOptions = {
        a11y: {
            prevSlideMessage: t('accessibility.swiper.previous_slide'),
            nextSlideMessage: t('accessibility.swiper.next_slide'),
            firstSlideMessage: t('accessibility.swiper.first_slide'),
            lastSlideMessage: t('accessibility.swiper.last_slide'),
            paginationBulletMessage: `${t('accessibility.swiper.go_to_slide')} {{index}}`,
            enabled: true,
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        controller: {},
        direction: 'horizontal',
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
        mousewheel: withMouse,
        loop: false,
        modules: [
            A11y,
            Autoplay,
            Thumbs,
            Keyboard,
            ...(withMouse ? [Mousewheel] : []),
        ],
        slidesPerView: 1,
        ...(isSwiperWithMultipleSlidesVisible.value ? {} : { spaceBetween: slideGap ?? 30 }),
        speed: 1000,
        thumbs: {},
    }

    // Customize settings based on the type prop
    switch (type) {
        case 'carousel':
            settings.slidesPerView = 'auto'
            break
        case 'banner':
            settings.loop = true
            // settings.pagination = paginationSettings.value
            settings.autoplay = {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            }
            break
        case 'gallery-main':
            settings.autoplay = false
            break
        case 'gallery-thumbs':
            settings.direction = clientDirection.value
            settings.autoplay = false
            settings.navigation = false
            settings.slidesPerView = 'auto'
            break
    }

    return settings
})

const swiperEl = useTemplateRef<HTMLElement>('swiperEl')

const { injected } = useCoreSwiperWrapperProvide()
if (!injected && import.meta.dev) {
    console.error('[CoreSwiper]: Component must be used within a <CoreSwiperWrapper />')
}

injected?.setNumberOfSlides(slides.length)

// TODO: add more flexibility
if (type === 'gallery-thumbs') {
    injected?.callbackInitializeThumbs((swiper, swiperParent) => {
        swiperParent.params.thumbs = swiperParent.params.thumbs || {}
        swiperParent.params.thumbs.swiper = swiper
        swiperParent.params.thumbs.autoScrollOffset = 1
        swiperParent.params.thumbs.slideThumbActiveClass = 'swiper-slide-thumb-active'
        swiperParent.params.thumbs.thumbsContainerClass = 'swiper-thumbs'
        swiperParent.params.thumbs.multipleActiveThumbs = false

        swiperParent.thumbs.init()

        swiperParent.on('realIndexChange', () => {
            activeSlideIndex.value = swiperParent.realIndex
        })
    })
}

// END REACH EVENT

const currentlyVisibleIndexes = shallowRef<number[]>([])
const hasReachedEndWithThreshold = computed<boolean>(() => {
    return slides.length - (currentlyVisibleIndexes.value[currentlyVisibleIndexes.value.length - 1] ?? 0) <= endThreshold
})

watch(hasReachedEndWithThreshold, (hasReachedEnd) => {
    if (!hasReachedEnd) return
    emit('endReached')
})

// COMPUTED NUMBER OF SLIDES

/**
 * Update the swiper instance when the slides change.
 * (recalculates all needed values)
 */
watch(() => slides, async () => {
    await nextTick()
    injected?.setNumberOfSlides(slides.length)
    injected?.swiper.value?.update()
})

// INITIALIZATION

onMounted(() => {
    if (!swiperEl.value) {
        if (import.meta.dev) {
            throw new Error('Swiper element not found')
        }
        return
    }

    onUpdateAndInit()

    injected?.setSwiper(new Swiper(swiperEl.value, {
        ...swiperSettings.value,
        on: {
            init: handleCurrentlyVisibleIndexes,
            slideChangeTransitionStart: handleCurrentlyVisibleIndexes,
            realIndexChange: onUpdateAndInit,
        },
    }))
})

const onUpdateAndInit = (swiper: Swiper | undefined = undefined) => {
    if (swiper) injected?.setActiveSlideIndex(swiper.realIndex)
}

const handleCurrentlyVisibleIndexes = (swiper: Swiper) => {
    currentlyVisibleIndexes.value = Array.from({ length: swiper.slidesPerViewDynamic() })
        .map((_, i) => swiper.realIndex + i)
}

function isSlideInert(index: number) {
    // TODO: refine inert slides
    if (type.startsWith('gallery-thumbs')) return undefined

    if (type === 'carousel') {
        // do not set inert when not initialized
        if (currentlyVisibleIndexes.value.length === 0) return undefined
    }
    return !currentlyVisibleIndexes.value.includes(index)
}

</script>

<style lang="scss" scoped>
@use "@core-scss/abstract/_variables" as vars;
@use "swiper/swiper-bundle.css";

$thumbs-gap: 15px;
$carousel-gap: 30px;

.sim-swiper {
    position: relative;
    isolation: isolate;
}

.sim-swiper-vertical {
    height: 100%;
    touch-action: pan-y;

    .swiper-wrapper {
        flex-direction: column;
    }
}

.swiper-slide {
    user-select: none;
}

.sim-swiper--gallery-main .swiper {
    height: 100%;
}

.sim-swiper--gallery-main .swiper-slide {
    height: auto;
}

.sim-swiper--carousel .swiper-slide {
    width: auto !important;
    height: auto;
    margin-right: $carousel-gap;
}

.sim-swiper--gallery-thumbs .sim-swiper-vertical .swiper-slide {
    margin-bottom: $thumbs-gap;
    height: auto;
}

.sim-swiper--gallery-thumbs .sim-swiper-horizontal .swiper-slide {
    margin-right: $thumbs-gap;
    width: auto;
}

@each $breakpoint, $value in vars.$grid-breakpoints {
    @include more-than($breakpoint) {
        .sim-swiper-vertical--#{$breakpoint} {
            height: 100%;
            touch-action: pan-y;

            .swiper-wrapper {
                flex-direction: column;
            }
        }

        .sim-swiper--gallery-thumbs .sim-swiper-vertical--#{$breakpoint} .swiper-slide {
            margin-bottom: $thumbs-gap;
            height: auto;
            margin-right: unset;
            width: unset;
        }
    }
}

@each $breakpoint, $value in vars.$grid-breakpoints {
    @include more-than($breakpoint) {
        .sim-swiper-horizontal--#{$breakpoint} {
            height: unset;
            touch-action: pan-x;

            .swiper-wrapper {
                flex-direction: row;
            }
        }

        .sim-swiper--gallery-thumbs .sim-swiper-horizontal--#{$breakpoint} .swiper-slide {
            margin-bottom: unset;
            height: unset;
            margin-right: $thumbs-gap;
            width: auto;
        }
    }
}

</style>

<template>
    <CoreSwiperNavigation v-slot="{ slidePrev, slideNext }">
        <div v-bind="$attrs">
            <button type="button"
                    class="sim-swiper__nav sim-swiper__nav--prev"
                    @click="slidePrev"
            >
                <IconArrowCircleRight
                    up
                    :aria-label="$t('accessibility.icons.left_arrow')"
                />
            </button>
            <button type="button"
                    class="sim-swiper__nav sim-swiper__nav--next"
                    @click="slideNext"
            >
                <IconArrowCircleRight
                    :aria-label="$t('accessibility.icons.right_arrow')"
                />
            </button>
        </div>
    </CoreSwiperNavigation>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-swiper__nav {
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 100vmax;
    stroke: $sim-c-primary-900;

    background: $sim-c-white;

    @include accessibility-focus-outline-offset;

    transition: background-color $sim-trans-time-normal $sim-timing, color $sim-trans-time-normal $sim-timing, stroke $sim-trans-time-normal $sim-timing;

    &:hover:not(.sim-swiper__nav--disabled) {
        background-color: $sim-c-primary-900;
        stroke: $sim-c-primary-900;
        color: $sim-c-white;
    }
}

.sim-swiper__nav--disabled {
    color: $sim-c-primary-400;
    stroke: $sim-c-primary-400;
    cursor: not-allowed;
}

.sim-swiper__nav--prev {
    left: 0;
    transform: translate(-155%, -50%);
}

.sim-swiper__nav--next {
    right: 0;
    transform: translate(155%, -50%);
}

</style>

<template>
    <slot v-bind="{
        slideNext,
        slidePrev,
        isLastSlide: slotData.isLastSlide,
        isFirstSlide: slotData.isFirstSlide,
        areMultipleSlides: slotData.areMultipleSlides,
    }"
    />
</template>

<script lang="ts" setup>

const { injected } = useCoreSwiperWrapperProvide()

function slideNext() {
    injected?.swiper.value?.slideNext()
}

function slidePrev() {
    injected?.swiper.value?.slidePrev()
}

const slotData = computed(() => {
    return {
        isLastSlide: !injected ? false : injected.numberOfSlides.value - 1 === injected.activeSlideIndex.value,
        isFirstSlide: !injected ? false : injected.activeSlideIndex.value === 0,
        areMultipleSlides: !injected ? true : injected.numberOfSlides.value > 1,
    }
})

</script>

<style lang="scss" scoped>

</style>

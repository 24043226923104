<template>
    <slot />
</template>

<script lang="ts" setup>
import {
    type CoreSwiperWrapperProvide,
    SymbolCoreSwiperWrapper
} from '@core/app/composables/components'
import type Swiper from 'swiper'

const _swiper: Ref<Swiper | null> = ref(null)
const _swiperParent: Ref<Swiper | null | undefined> = ref(null)
const _numberOfSlides = ref<number>(0)
const _activeSlideIndex = ref<number>(0)

const _callbackSetParent = ref<((parent: Swiper) => void) | null>(null)
const _callbackInitializeThumbs = ref<((swiper: Swiper, parent: Swiper) => void) | null>(null)

const { injected } = useCoreSwiperWrapperProvide()

injected?.callbackSetParent((swiperParent) => {
    _swiperParent.value = swiperParent
})

provide<CoreSwiperWrapperProvide>(SymbolCoreSwiperWrapper, {
    swiper: _swiper,
    swiperParent: _swiperParent,
    numberOfSlides: _numberOfSlides,
    activeSlideIndex: _activeSlideIndex,
    setSwiper: (swiper: Swiper) => {
        _swiper.value = swiper
        _callbackSetParent.value?.(swiper)

        nextTick(() => {
            // TODO: add more flexibility
            if (_swiperParent.value) {
                _callbackInitializeThumbs.value?.(swiper, _swiperParent.value)
            }
        })
    },
    setNumberOfSlides: (value: number) => {
        _numberOfSlides.value = value
    },
    setActiveSlideIndex: (value: number) => {
        _activeSlideIndex.value = value
    },
    callbackSetParent: (callback: (parent: Swiper) => void) => {
        _callbackSetParent.value = callback
    },
    callbackInitializeThumbs: (callback: (swiper: Swiper, parent: Swiper) => void) => {
        _callbackInitializeThumbs.value = callback
    },
})


</script>

<style lang="scss" scoped>

</style>

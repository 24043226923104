import PhotoSwipeLightbox from 'photoswipe/lightbox'
import type { TemplateRef } from '@core/types/utility'

type PhotoswipeGalleryInput = TemplateRef<HTMLElement | ComponentPublicInstance> | TemplateRef<HTMLElement | ComponentPublicInstance>[]
type PhotoswipeChildrenInput = TemplateRef<HTMLElement | ComponentPublicInstance> | TemplateRef<HTMLElement | ComponentPublicInstance>[] | string
export type PhotoswipeItem = {
    src: string
    width: number
    height: number
}

export function usePhotoswipeOpenState() {
    return useState('pswp-open', () => false)
}

type UsePhotoswipeOptions = ({
    gallery: PhotoswipeGalleryInput
    children?: PhotoswipeChildrenInput
    items?: never
} | {
    gallery?: never
    children?: never
    items: MaybeRefOrGetter<(PhotoswipeItem | InstanceType<typeof ProductImageModel> | InstanceType<typeof ProductVideoModel>)[]>
    thumbnails?: TemplateRef<(HTMLElement | ComponentPublicInstance | null)[]>
}) & {
    /**
     * Which transition to use when opening the lightbox.
     *
     * When using the non-DOM template, you need to provide the `thumbnails` to
     * use the 'zoom' transition.
     * @default 'zoom'
     */
    transition?: 'zoom' | 'fade' | 'none'
    /**
     * The number of slides to preload before the current slide.
     * @default 1
     */
    preloadBefore?: number
    /**
     * The number of slides to preload after the current slide.
     * @default 2
     */
    preloadAfter?: number
}

function getElement(el: MaybeRefOrGetter<HTMLElement | ComponentPublicInstance | null>): HTMLElement | null {
    const e = toValue(el)
    if (e && '$el' in e) return e.$el
    return e
}

function normalizeElements(el: MaybeRefOrGetter<HTMLElement | ComponentPublicInstance | null> | MaybeRefOrGetter<HTMLElement | ComponentPublicInstance | null>[] | string): HTMLElement | HTMLElement[] | string | null {
    return Array.isArray(el) ? el.map(getElement).filter((el) => {
        if (el === null && import.meta.dev) {
            warnLog('[usePhotoswipe]: An element in the array is `null`', el)
        }
        return el !== null
    }) as HTMLElement[] : typeof el === 'string' ? el : getElement(el)
}

type Lightbox = {
    openIndex: (index: number) => void
}

/**
 * A composable wrapper around the PhotoSwipe library.
 *
 * The lightbox can be initialized in two ways:
 * - DOM template: Provide the `gallery` and optionally the `children` to use
 * the DOM elements as the lightbox items.
 *
 * - Non-DOM template: Provide the `items` to use the lightbox items in a programmatic way.
 * @param options
 */
export function usePhotoswipe(options: UsePhotoswipeOptions): Lightbox {
    const lightbox = ref<PhotoSwipeLightbox | null>(null)
    const { t } = useI18n()

    const isDomTemplate = options.items === undefined

    const items = isDomTemplate ? null : computed<(PhotoswipeItem | InstanceType<typeof ProductVideoModel>)[]>(() => {
        return toValue(options.items).map((item) => {
            if (item instanceof ProductImageModel) {
                return {
                    src: item.imageUrl ?? '',
                    width: item.width ?? 0,
                    height: item.height ?? 0,
                } satisfies PhotoswipeItem
            }
            return item
        })
    })

    onMounted(() => {
        const gallery = options.gallery ? normalizeElements(options.gallery) : null
        const children = options.children ? normalizeElements(options.children) : gallery ? 'a' : null

        if (gallery === null && isDomTemplate) {
            if (import.meta.dev) {
                warnLog('[usePhotoswipe]: The gallery element is `null`', options.gallery)
            }
            return
        }

        const isOpen = usePhotoswipeOpenState()

        lightbox.value = new PhotoSwipeLightbox({
            ...(isDomTemplate ? {
                // DOM TEMPLATE
                gallery: gallery!,
                children: children ?? undefined,
                showHideAnimationType: options.transition,
            } : {
                // NON-DOM TEMPLATE
                showHideAnimationType: options.transition
                    ? options.transition
                    : options.thumbnails ? 'zoom' : 'fade',
            }),
            pswpModule: () =>
                // @ts-ignore
                import('photoswipe'),
            ...(options.preloadBefore !== undefined || options.preloadAfter !== undefined ? {
                preload: [options.preloadBefore ?? 0, options.preloadAfter ?? 0],
            } : {}),
            closeTitle: t('_core_simploshop.accessibility.pswp.close'),
            zoomTitle: t('_core_simploshop.accessibility.pswp.zoom'),
            arrowPrevTitle: t('_core_simploshop.accessibility.pswp.previous'),
            arrowNextTitle: t('_core_simploshop.accessibility.pswp.next'),
            errorMsg: t('_core_simploshop.accessibility.pswp.error'),
        })

        // update the global open state
        lightbox.value.on('beforeOpen', () => {
            isOpen.value = true
        })
        lightbox.value.on('closingAnimationEnd', () => {
            isOpen.value = false
        })

        // load items dynamically
        if (!isDomTemplate) {
            lightbox.value.addFilter('numItems', () => {
                return items?.value.length ?? 0
            })

            lightbox.value.addFilter('itemData', (itemData, index) => {
                const item = items?.value[index]
                if (import.meta.dev && !item) {
                    errorLog(`[usePhotoswipe]: The item on index <${index}> is \`undefined\``, items?.value)
                }

                if (item instanceof ProductVideoModel) {
                    return {
                        type: 'video',
                    }
                }

                return {
                    src: item?.src ?? '',
                    w: item?.width ?? 0,
                    h: item?.height ?? 0,
                }
            })

            if (options.thumbnails) {
                lightbox.value.addFilter('thumbEl', (thumbEl, data, index) => {
                    const el = toValue(options.thumbnails)?.[index]
                    if (el === null && import.meta.dev) {
                        warnLog(`[usePhotoswipe]: The thumbnail element on index <${index}> is \`null\``, el)
                    }
                    return (el ? getElement(el) : thumbEl) as HTMLElement
                })

                lightbox.value.addFilter('placeholderSrc', (placeholderSrc, slide) => {
                    // TODO: optimize image size
                    return slide.data.src ?? placeholderSrc
                })
            }
        }

        // handle video content
        lightbox.value.on('contentLoad', (e) => {
            const { content } = e
            if (content.type === 'video') {
                e.preventDefault()

                let videoEmbedUrl

                if (isDomTemplate) {
                    videoEmbedUrl = content.data.src
                    if (!videoEmbedUrl) {
                        errorLog(`[usePhotoswipe]: The video embed URL is \`undefined\` for slide on index <${content.index}>`, content.slide)
                        return
                    }
                } else {
                    const item = items?.value[content.index]
                    if (item instanceof ProductVideoModel) {
                        videoEmbedUrl = item.getIframeUrl()

                        if (!videoEmbedUrl) {
                            errorLog(`[usePhotoswipe]: The video embed URL is \`null\` for item on index <${content.index}>`, item)
                            return
                        }
                    } else {
                        errorLog(`[usePhotoswipe]: The item on index <${content.index}> is not an instance of \`ProductVideoModel\``, item)
                        return
                    }
                }

                content.element = document.createElement('div')
                content.element.className = 'pswp__video-container'


                const iframe = document.createElement('iframe')
                iframe.src = videoEmbedUrl
                content.element.appendChild(iframe)

                iframe.focus()
            }
        })


        lightbox.value.init()
    })

    onUnmounted(() => {
        if (!lightbox.value) return
        lightbox.value.destroy()
        lightbox.value = null
    })

    return {
        openIndex(index: number) {
            if (!lightbox.value) {
                errorLog('[usePhotoswipe:openIndex]: The lightbox is not initialized')
                return
            }

            lightbox.value.loadAndOpen(index)
        },
    }
}

<template>
    <CoreSwiperWrapper>
        <CoreSwiper type="carousel"
                    :slides="slides"
                    :with-mouse="withMouse"
                    @end-reached="emit('endReached')"
        >
            <template #slide="{ slide }">
                <slot name="slide" :slide="slide" />
            </template>

            <SwiperNavigation class="sim-carousel__navigation" />

        </CoreSwiper>
    </CoreSwiperWrapper>
</template>

<script lang="ts" setup generic="T">

const {
    slides,
    withMouse,
} = defineProps<{
    slides: T[]
    withMouse?: boolean
}>()

const emit = defineEmits<{
    endReached: []
}>()

</script>

<style lang="scss" scoped>

:deep(.sim-carousel__navigation) {
    display: none;

    @include more-than(lg) {
        display: block;
    }
}

</style>
